import React, { useState, useEffect, useCallback } from 'react';
import './NewOrder.css';
import fetchSuggestions from '../../utils/yandexGeosuggest';
import getCoordinatesByURI from '../../utils/yandexGeocoder';
import { debounce } from 'lodash';
import axios from 'axios';
import { fetchCurrentOrders } from '../../redux/slices/currentOrdersSlice';
import { useDispatch } from 'react-redux';

function NewOrder({ onFromAddressSelected, onToAddressSelected, fromAddress, toAddress, STARTCOORDS, ENDCOORDS, LOCALITY }) {
  const [formData, setFormData] = useState({
    from: fromAddress,
    to: toAddress,
    tariffClass: 'econom',
    phoneNumber: ''
  });

  const [suggestions, setSuggestions] = useState([]);
  const [locality, setLocality] = useState(LOCALITY);
  const [activeField, setActiveField] = useState(null);
  const [tempValue, setTempValue] = useState(null);
  const [startCoords, setStartCoords] = useState(STARTCOORDS)
  const [endCoords, setEndCoords] = useState(ENDCOORDS)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('Эконом');
  // const [isCodeRequired, setIsCodeRequired] = useState(false);
  // const [sessionId, setSessionId] = useState('');
  // const [confirmationCode, setConfirmationCode] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      from: fromAddress,
      to: toAddress
    }));
    setStartCoords(STARTCOORDS);
    setEndCoords(ENDCOORDS);
  }, [fromAddress, toAddress, STARTCOORDS, ENDCOORDS]);

  useEffect(() => {
    setLocality(LOCALITY);
  }, [LOCALITY]);

  const debouncedFetchSuggestions = useCallback(
    debounce(async (value) => {
      const suggestions = await fetchSuggestions(value);
      setSuggestions(suggestions);
    }, 400), 
    []
  );
  
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'from' || name === 'to') {
      setTempValue(value);
      debouncedFetchSuggestions(value);
    } else {
      setFormData({
        ...formData,
        [name]: value
    });
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setActiveField(null);
      setTempValue('');
    }, 150);
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setActiveField(name);
    setTempValue(formData.name);
  };

  const renderHighlightedText = (text, highlights) => {
    if (!highlights) return text;
    let lastIndex = 0;
    const parts = [];

    highlights.forEach((highlight, index) => {
      parts.push(text.substring(lastIndex, highlight.begin));
      parts.push(<span key={index} className="highlight">{text.substring(highlight.begin, highlight.end)}</span>);
      lastIndex = highlight.end;
    });
    parts.push(text.substring(lastIndex));

    return parts;
  };

  const handleSelectSuggestion = async (suggestion, field) => {
    if (field === 'from') {
      setLocality(suggestion.locality);
      setFormData(prevFormData => ({ ...prevFormData, from: suggestion.title }));
      const fromCoords = await getCoordinatesByURI(suggestion.uri);
      setStartCoords(fromCoords);
      onFromAddressSelected(suggestion.title, fromCoords);
    } else {
      setFormData(prevFormData => ({ ...prevFormData, to: suggestion.title }));
      const toCoords = await getCoordinatesByURI(suggestion.uri);
      setEndCoords(toCoords);
      onToAddressSelected(suggestion.title, toCoords, true);
    }
  };

  const handleDropdownToggle = (event) => {
    event.preventDefault();
    setDropdownOpen(!dropdownOpen);
  }
  
  const handleDropdownSelect = (option, value) => {
    setSelectedOption(option);
    setDropdownOpen(false);
    setFormData((prevData) => ({
      ...prevData,
      tariffClass: value
    }));
  };

  // const showCodeInputForm = () => {
  //   setIsCodeRequired(true);
  // };
  
  // const submitConfirmationCode = async () => {
  //   try {
  //     const response = await axios.post('https://taxi-ru-api.vercel.app/api/order/submit-code', {
  //       sessionId: sessionId, 
  //       code: confirmationCode
  //     })
  
  //     if (response.data.content) {
  //       console.log('Code submitted and logged in successfully.');
  //     } else {
  //       console.error('Failed to submit code or login.');
  //     }
  //   } catch (error) {
  //     console.error('Error submitting confirmation code:', error);
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;

    if (form.checkValidity() === false) {
      form.classList.add('was-validated');
      return;
    }

    // const phoneNumberInput = form.querySelector('input[name="phoneNumber"]'); 
    // const phonePattern = /^[+]?[0-9]{10,15}$/; 

    // if (!phonePattern.test(phoneNumberInput.value)) {
    //   phoneNumberInput.setCustomValidity('Некорректный формат номера телефона.');
    //   form.classList.add('was-validated');
    //   return;
    // } else {
    //     phoneNumberInput.setCustomValidity('');
    // }

    const phoneNumberInput = formData.phoneNumber;
    navigator.clipboard.writeText(phoneNumberInput).then(() => {
      console.log('Phone number copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy phone number: ', err);
    });

    try {
      const tariffClass = formData.tariffClass;
      const link = `https://3.redirect.appmetrica.yandex.com/route?start-lat=${startCoords.lat}&start-lon=${startCoords.lon}&end-lat=${endCoords.lat}&end-lon=${endCoords.lon}&tariffClass=${tariffClass}&ref=7806894&appmetrica_tracking_id=1178268795219780156`;
      window.open(link, '_blank');

      try {
        const orderData = {
          phone_number: formData.phoneNumber,
          address_from: formData.from,
          address_to: formData.to,
          tariff_class: formData.tariffClass,
          created_at: new Date().toISOString().slice(0, 19).replace('T', ' ')
        };

        await axios.post('https://taxi-ru-api.vercel.app/api/current-orders', orderData, {
          withCredentials: true
        });
      } catch (error) {
        console.error('Error adding order to the database:', error);
      }

      try {
        await axios.post('https://taxi-ru-api.vercel.app/api/order-count', {
          date: new Date().toLocaleString("en-US", { timeZone: "Europe/Moscow" })
        }, {
          withCredentials: true
        });
      } catch (error) {
        console.error('Error incrementing order count:', error);
      }

      try {
        await axios.post('https://taxi-ru-api.vercel.app/api/customers', {
          phone_number: formData.phoneNumber,
          city: locality
        }, {
          withCredentials: true
        });
      } catch (error) {
        console.error('Error adding customer to the database:', error);
      }

      onFromAddressSelected('', {lon: 37.588144, lat: 55.733842});
      onToAddressSelected('', {}, false);

      setFormData({
        from: '',
        to: '',
        tariffClass: 'econom',
        phoneNumber: ''
      });

      form.classList.remove('was-validated');

      setSuggestions([]);
      onToAddressSelected('', {}, false);

      dispatch(fetchCurrentOrders());

    } catch (error) {
      console.error('Error getting coordinates:', error);
    }

    // const response = await axios.get('https://taxi-ru-api.vercel.app/api/order/test');

    // if (response.data.status === 'code_requested') {
    //   sessionId = response.data.sessionId;
    //   setSessionId(sessionId);
    //   showCodeInputForm();
    // }
  };

  return (
    <div className="new-order-container">
      <form className="form-container needs-validation" onSubmit={handleSubmit} noValidate>
        <div className="form-group">
          <label htmlFor="from">Откуда:</label>
          <textarea 
            type="text" 
            className="form-control" 
            id="from" 
            name="from" 
            placeholder='Откуда' 
            value={activeField === 'from' ? tempValue : formData.from}
            onChange={handleChange} 
            onFocus={handleFocus} 
            onBlur={handleBlur} 
            required
            ></textarea>
          <div className="invalid-feedback">Укажите откуда забирать</div>
          {activeField === 'from' && suggestions.length > 0 ? (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li key={index} onClick={() => handleSelectSuggestion(suggestion, 'from')}>
                  <span className="title">{renderHighlightedText(suggestion.title, suggestion.titleHl)}</span>
                  <span className="subtitle">{renderHighlightedText(suggestion.subtitle, suggestion.subtitleHl)}</span>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="to">Куда:</label>
          <textarea 
            type="text" 
            className="form-control" 
            id="to" 
            name="to" 
            placeholder='Куда' 
            value={activeField === 'to' ? tempValue : formData.to}
            onChange={handleChange} 
            onFocus={handleFocus} 
            onBlur={handleBlur} 
            required
          ></textarea>
          <div className="invalid-feedback">Укажите куда ехать</div>
          {activeField === 'to' && suggestions.length > 0 ? (
            <ul className="suggestions-list">
              {suggestions.map((suggestion, index) => (
                <li key={index} onClick={() => handleSelectSuggestion(suggestion, 'to')}>
                  <span className="title">{renderHighlightedText(suggestion.title, suggestion.titleHl)}</span>
                  <span className="subtitle">{renderHighlightedText(suggestion.subtitle, suggestion.subtitleHl)}</span>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="tariff-class">Тариф:</label>
            <div className={`tariff-dropdown ${dropdownOpen ? 'open' : ''}`}>
              <button className={'tariff-dropdown-button'} onClick={(event) => {handleDropdownToggle(event)}}>
                {selectedOption} <svg width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="m8 9.583 4.793-4.79a1 1 0 0 1 1.414 1.414l-5.5 5.497a1 1 0 0 1-1.414 0l-5.5-5.497a1 1 0 0 1 1.414-1.414L8 9.583Z" fill="currentColor"></path></svg>
              </button>
              {dropdownOpen && (
                <div className="tariff-dropdown-menu">
                  {[
                    { label: 'Эконом', value: 'econom' },
                    { label: 'Комфорт', value: 'business' },
                    { label: 'Комфорт+', value: 'comfortplus' },
                    { label: 'Минивен', value: 'minivan' },
                    { label: 'Бизнес', value: 'vip' },
                    { label: 'Доставка', value: 'express' },
                    { label: 'Курьер', value: 'courier' }
                  ].map(option => (
                    <div key={option.value} className={`tariff-dropdown-item ${option.label === selectedOption ? 'selected' : ''}`} onClick={() => handleDropdownSelect(option.label, option.value)}>
                      {option.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="invalid-feedback">Выберите тариф</div>
          </div>
          <div className="form-group">
            <label htmlFor="phone-number">Номер телефона:</label>
            <input type="tel" className="form-control" id="phone-number" name="phoneNumber" placeholder='Номер телефона' value={formData.phoneNumber} onChange={handleChange} required></input>
            <div className="invalid-feedback">Введите номер телефона</div>
          </div>
        </div>
        <button type="submit" className="submit-btn">Сделать заказ</button>
      </form>

      {/* {isCodeRequired && (
        <div className="code-input-form">
          <label htmlFor="confirmationCode">Enter Confirmation Code:</label>
          <input
            type="text"
            id="confirmationCode"
            name="confirmationCode"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
          <button type="button" onClick={submitConfirmationCode}>Submit Code</button>
        </div>
      )} */}
    </div>
  );
}

export default NewOrder;