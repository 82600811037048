import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import axios from 'axios';
import { subDays, isWithinInterval, startOfMonth, startOfYear, endOfYear, endOfMonth, subMonths, 
  startOfQuarter, endOfQuarter } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const initialState = {
  orderCounts: [],
  filteredOrderCounts: [],
  filter: 'thisMonth',
  status: 'idle',
  error: null,
};

export const fetchOrderCounts = createAsyncThunk('orderCounts/fetchOrderCounts', async () => {
  const response = await axios.get('https://taxi-ru-api.vercel.app/api/order-count', {
    withCredentials: true
  });
  return response.data;
});

const applyFilter = (orderCounts, filter) => {
  const timeZone = 'Europe/Moscow';
  const now = toZonedTime(new Date(), timeZone);
  let startDate;
  let endDate = now;

  switch (filter) {
    case '30days':
      startDate = subDays(now, 30);
      break;
    case '90days':
      startDate = subDays(now, 90);
      break;
    case 'thisMonth':
      startDate = startOfMonth(now);
      break;
    case 'thisYear':
      startDate = startOfYear(now);
      break;
    case 'Сегодня':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      break;
    case 'Вчера':
      startDate = subDays(now, 1);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
      break;
    case 'Прошлый месяц':
      startDate = startOfMonth(subMonths(now, 1));
      endDate = endOfMonth(subMonths(now, 1));
      break;
    case 'Прошлый квартал':
      const lastQuarterDate = subMonths(now, 3);
      startDate = startOfQuarter(lastQuarterDate);
      endDate = endOfQuarter(lastQuarterDate);
      break;
    case 'Прошлый год':
      startDate = startOfYear(subMonths(now, 12));
      endDate = endOfYear(subMonths(now, 12));
      break;
    case '7 дней':
      startDate = subDays(now, 7);
      break;
    case '10 дней':
      startDate = subDays(now, 10);
      break;
    case '180 дней':
      startDate = subDays(now, 180);
      break;
    case '365 дней':
      startDate = subDays(now, 365);
      break;
    default:
      return orderCounts;
  }

  return orderCounts.filter(order => {
    const orderDate = toZonedTime(new Date(order.order_date), timeZone);
    return isWithinInterval(orderDate, { start: startDate, end: endDate });
  });
};

const orderCountSlice = createSlice({
  name: 'orderCounts',
  initialState,
  reducers: {
    updateOrderCount: (state, action) => {
      const { id, order_date, order_count } = action.payload;
      const existingOrder = state.orderCounts.find(order => order.id === id);
      if (existingOrder) {
        existingOrder.order_date = order_date;
        existingOrder.order_count = order_count;
      }
      state.filteredOrderCounts = applyFilter(state.orderCounts, state.filter);
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
      state.filteredOrderCounts = applyFilter(state.orderCounts, action.payload);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderCounts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOrderCounts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.orderCounts = action.payload;
        state.filteredOrderCounts = applyFilter(action.payload, state.filter);
      })
      .addCase(fetchOrderCounts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { updateOrderCount, setFilter } = orderCountSlice.actions;
export const selectOrderCounts = (state) => state.orderCounts.filteredOrderCounts || [];

const persistConfig = {
  key: 'orderCounts',
  storage,
};

const persistedOrderCountsReducer = persistReducer(persistConfig, orderCountSlice.reducer);

export default persistedOrderCountsReducer;