import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import CurrentOrders from './pages/current-orders/CurrentOrders';
import CustomerRecords from './pages/customer-records/CustomerRecords';
import OrderCounts from './pages/order-counts/OrderCounts';
import ForbiddenPage from './pages/forbidden/ForbiddenPage';
import useRoleAccess from './hooks/useRoleAccess';

function App() {
  const hasExecutiveAccess = useRoleAccess(['executive']);
  const hasAnyAccess = useRoleAccess(['executive', 'dispatcher']);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={hasAnyAccess ? <Navigate to="/" /> : <Login />} />
        
        <Route path="/" element={hasAnyAccess ? <Home /> : <Navigate to="/login" />} />
        <Route path="/current-orders" element={hasAnyAccess ? <CurrentOrders /> : <Navigate to="/login" />} />


        <Route path="/customer-records" element={hasExecutiveAccess ? <CustomerRecords /> : <ForbiddenPage />} />
        <Route path="/order-counts" element={hasExecutiveAccess ? <OrderCounts /> : <ForbiddenPage />} />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;
