import './Login.css';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../redux/slices/authSlice';
import axios from 'axios';

function Login() {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
  
    try {
      const response = await axios.post('https://taxi-ru-api.vercel.app/api/login', { username, password }, {
        withCredentials: true,
      });
  
      if (response.data.message === 'Login successful') {
        dispatch(authenticate({ role: response.data.role }));
      }
    } catch (err) {
      setError('Неверное имя пользователя или пароль.');
    }
  };
  

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Войти в систему</h2>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            value={username}
            onChange={(event) => setUsername(event.target.value)} 
            placeholder="Имя пользователя"
            required
          />
          <input 
            type="password" 
            value={password} 
            onChange={(event) => setPassword(event.target.value)} 
            placeholder="Пароль"
            required
          />
          {error && <p style={{ color: 'red' }}>{error}</p>}
          <button type="submit">Войти</button>
        </form>
      </div>
    </div>
  );
}

export default Login;