import axios from 'axios';

const GEOCODER_API_KEY = 'b31b7369-3a02-4045-be08-e8eb332499de';

const getCoordinatesByURI = async (uri) => {
  try {
    const response = await axios.get('https://geocode-maps.yandex.ru/1.x/', {
      params: {
          apikey: GEOCODER_API_KEY,
          sco: 'latlong',
          uri: uri,
          format: 'json'
      }
    });

    console.log('Full geocoder response data:', response.data);

    const geoObject = response.data.response.GeoObjectCollection.featureMember[0].GeoObject;
    const coordinates = geoObject.Point.pos.split(' ').map(Number);
    return { lat: coordinates[1], lon: coordinates[0] };
  } catch (error) {
    console.error('Error fetching coordinates:', error);
    throw error; 
  }
};

const getAddressByCoordinates = async (lat, lon) => {
  try {
    const response = await axios.get('https://geocode-maps.yandex.ru/1.x/', {
      params: {
        apikey: GEOCODER_API_KEY,
        geocode: `${lon},${lat}`,
        format: 'json'
      }
    });

    const geoObject = response.data.response.GeoObjectCollection.featureMember[0].GeoObject;
    const address = geoObject.name;

    const components = geoObject.metaDataProperty.GeocoderMetaData.Address.Components;
    const localityComponent = components.find(component => component.kind === 'locality');
    const locality = localityComponent ? localityComponent.name : 'Неизвестно';

    return { address, locality };
  } catch (error) {
    console.error('Error fetching address:', error);
    return null;
  }
};

export default getCoordinatesByURI;
export { getAddressByCoordinates };