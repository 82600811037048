import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import currentOrdersReducer from './slices/currentOrdersSlice';
import orderCountsReducer from './slices/orderCountsSlice';
import customersReducer from './slices/customersSlice';
import storage from 'redux-persist/lib/storage'; 
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';

const persistConfig = {
  key: 'root', 
  storage, 
  whitelist: ['auth', 'currentOrders', 'orderCounts', 'customers']
};

const rootReducer = combineReducers({
  auth: authReducer,
  currentOrders: currentOrdersReducer,
  orderCounts: orderCountsReducer,
  customers: customersReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, 
    }),
});

export const persistor = persistStore(store);
