import React from 'react';
import './ForbiddenPage.css';

const ForbiddenPage = () => {
  return (
    <div className="forbidden-container">
      <div className="forbidden-box">
        <h2>403 - Доступ запрещен</h2>
        <p>У вас нет прав для доступа к этой странице.</p>
      </div>
    </div>
  );
};

export default ForbiddenPage;
