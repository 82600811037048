import { useSelector } from 'react-redux';

const useRoleAccess = (allowedRoles) => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  const userRole = useSelector(state => state.auth.role);

  return isAuthenticated && allowedRoles.includes(userRole);
};

export default useRoleAccess;
