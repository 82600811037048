import React, { useEffect, useState, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import './Map.css';
import { getAddressByCoordinates } from '../../utils/yandexGeocoder';

const Map = ({ CENTER, STARTCOORDS, ENDCOORDS, onFromAddressChange, onToAddressChange, toAddressSelected, onLocalityChange }) => {
  const [YMapComponents, setYMapComponents] = useState({});
  const [LOCATION, setLOCATION] = useState({center: CENTER, zoom: 16});
  const ymap3Ref = useRef(null);
  const isDraggingRef = useRef(false);
  const isZoomingRef = useRef(false);
  const isPinchZoom = useRef(false);
  const isOneFingerZoom = useRef(false);
  
  useEffect(() => {
    const initMap = async () => {
      try {
        await window.ymaps3.ready;

        const ymaps3Reactify = await window.ymaps3.import('@yandex/ymaps3-reactify');
        const reactify = ymaps3Reactify.reactify.bindTo(React, ReactDOM);
        const {
          YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapControls, YMapScaleControl, YMapListener
        } = reactify.module(window.ymaps3);

        const {YMapZoomControl} = reactify.module(await window.ymaps3.import('@yandex/ymaps3-controls@0.0.1'));
        const {YMapGeolocationControl} = reactify.module(await window.ymaps3.import('@yandex/ymaps3-controls@0.0.1'));
        const {YMapDefaultMarker} = reactify.module(await window.ymaps3.import('@yandex/ymaps3-markers@0.0.1'));

        setYMapComponents({
          YMap,
          YMapDefaultSchemeLayer,
          YMapDefaultFeaturesLayer,
          YMapDefaultMarker,
          YMapListener,
          YMapControls,
          YMapScaleControl,
          YMapZoomControl,
          YMapGeolocationControl
        });
      } catch (error) {
        console.error('Error loading Yandex Maps components:', error);
      }
    };

    initMap();
  }, []);

  const {
    YMap, YMapDefaultSchemeLayer, YMapDefaultFeaturesLayer, YMapDefaultMarker, YMapListener,
    YMapControls, YMapScaleControl, YMapZoomControl, YMapGeolocationControl
  } = YMapComponents;

  useEffect(() => {
    if (toAddressSelected && STARTCOORDS.lat && STARTCOORDS.lon && ENDCOORDS.lat && ENDCOORDS.lon) {
      const bounds = [
        [Math.min(STARTCOORDS.lat, ENDCOORDS.lat), Math.min(STARTCOORDS.lon, ENDCOORDS.lon)],
        [Math.max(STARTCOORDS.lat, ENDCOORDS.lat), Math.max(STARTCOORDS.lon, ENDCOORDS.lon)]
      ];
      setLOCATION((prevLocation) => ({
        ...prevLocation,
        center: CENTER,
        zoom: getZoomLevel(bounds)
      }));
    } else {
        setLOCATION((prevLocation) => ({
          ...prevLocation,
          center: CENTER,
        }));
      } 
  }, [CENTER, STARTCOORDS, ENDCOORDS, toAddressSelected]);

  const getZoomLevel = (bounds) => {
    const latDiff = bounds[1][0] - bounds[0][0];
    const lonDiff = bounds[1][1] - bounds[0][1];
    const maxDiff = Math.max(latDiff, lonDiff);
    
    if (maxDiff > 1.0) return 10;
    if (maxDiff > 0.5) return 12;
    if (maxDiff > 0.01) return 15;
    return 16; 
  };

  const handleBehaviorStart = useCallback(({ type }) => {
    if (!toAddressSelected) {
      if (type === 'drag') {
        isDraggingRef.current = true;
      } else if (type === 'scrollZoom') {
          isZoomingRef.current = true;
        } else if (type === 'pinchZoom') {
            isPinchZoom.current = true;
          } else if (type === 'oneFingerZoom') {
              isOneFingerZoom.current = true;
            }
    }
  }, [toAddressSelected]);

  const handleBehaviorEnd = useCallback(async ({ type, location }) => {
    if (!toAddressSelected) {
      if (type === 'drag') {
        isDraggingRef.current = false;
        const { address: fromAddress, locality } = await getAddressByCoordinates(location.center[1], location.center[0]);
        if (fromAddress) {
          onFromAddressChange(fromAddress, { lat: location.center[1], lon: location.center[0] });
          onLocalityChange(locality);
        }
      } else if (type === 'scrollZoom') {
          isZoomingRef.current = false;
        } else if (type === 'pinchZoom') {
            isPinchZoom.current = false;
          } else if (type === 'oneFingerZoom') {
              isOneFingerZoom.current = false;
            }
    }
  }, [onFromAddressChange, toAddressSelected]);

  const handleMapUpdate = useCallback(({ location }) => {
    if (!toAddressSelected) {
      if (isDraggingRef.current) {
        setLOCATION({
          center: location.center,
          zoom: location.zoom,
        });
      } else if (isZoomingRef.current) {
          setLOCATION((prevLocation) => ({
            ...prevLocation,
            zoom: location.zoom
          }));
        } else if (isPinchZoom.current) {
            setLOCATION((prevLocation) => ({
              ...prevLocation,
              zoom: location.zoom
            }));
          } else if (isOneFingerZoom.current) {
              setLOCATION((prevLocation) => ({
                ...prevLocation,
                zoom: location.zoom
              }));
            }
    }
  }, [toAddressSelected]);

  const handleMarkerDragEndFrom = useCallback(async (coords) => {
    const { address: fromAddress, locality } = await getAddressByCoordinates(coords[1], coords[0]);
    if (fromAddress) {
      onFromAddressChange(fromAddress, { lat: coords[1], lon: coords[0] });
      onLocalityChange(locality);
      setLOCATION((prevLocation) => ({
        ...prevLocation,
        center: coords
      }));
    }
  }, [onFromAddressChange]);

  const handleMarkerDragEndTo = useCallback(async (coords) => {
    const { address: toAddress, locality } = await getAddressByCoordinates(coords[1], coords[0]);
    if (toAddress) {
      onToAddressChange(toAddress, { lat: coords[1], lon: coords[0] }, true);
    }
  }, [onToAddressChange]);

  if (
      !YMap || !YMapDefaultSchemeLayer || !YMapDefaultFeaturesLayer || !YMapDefaultMarker || !YMapControls || !YMapScaleControl || 
      !YMapZoomControl || !YMapGeolocationControl || !YMapListener) {
    return <div className="map-container">
             <h1>
               Загрузка карты...
             </h1>
           </div>
    }

  return (
    <div className="map-container">
      <div id="map" className="map">
        <YMap location={LOCATION} mode="vector" theme="dark" ref={ymap3Ref}>
          <YMapListener 
              onUpdate={handleMapUpdate}
              onActionStart={handleBehaviorStart}
              onActionEnd={handleBehaviorEnd}
          />
          <YMapDefaultSchemeLayer />
          <YMapDefaultFeaturesLayer />
          <YMapDefaultMarker 
            coordinates={LOCATION.center} 
            color={'black'} 
            title={'Откуда'}
            draggable={true} 
            mapFollowsOnDrag={true} 
            onDragEnd={handleMarkerDragEndFrom}
          />
          {toAddressSelected && ENDCOORDS.lon && ENDCOORDS.lat ? 
            <YMapDefaultMarker 
              coordinates={[ENDCOORDS.lon, ENDCOORDS.lat]} 
              color={'white'} 
              title={'Куда'}
              draggable={true}
              mapFollowsOnDrag={true}
              onDragEnd={handleMarkerDragEndTo}
            />
          : null}
          <YMapControls position="bottom left">
            <YMapScaleControl />
          </YMapControls>
          <YMapControls position="bottom">
            <YMapZoomControl />
            <YMapGeolocationControl />
          </YMapControls>
        </YMap>
      </div>
    </div>
  );
};

export default Map;