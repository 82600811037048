import React from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { logout } from '../../redux/slices/authSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import './LogoutButton.css';

const LogoutButton = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      await axios.post('https://taxi-ru-api.vercel.app/api/logout', {}, {
        withCredentials: true
      });

      dispatch(logout());
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <button onClick={handleLogout} className="logout-button">
      <FontAwesomeIcon icon={faSignOutAlt} className="logout-icon" />
      Выйти
    </button>
  );
};

export default LogoutButton;
