import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import LogoutButton from '../logout-button/LogoutButton';
import useRoleAccess from '../../hooks/useRoleAccess';

const Header = () => {
  const location = useLocation();
  const hasExecutiveAccess = useRoleAccess(['executive']);
  const isAuthenticated = useRoleAccess(['executive', 'dispatcher']);

  return (
    <div className='header-container'>
      <nav className="navbar">
        <ul>
          <li>
            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Сделать Заказ</Link>
          </li>
          <li>
            <Link to="/current-orders" className={location.pathname === '/current-orders' ? 'active' : ''}>Текущие Заказы</Link>
          </li>

          {hasExecutiveAccess && (
            <>
              <li>
                <Link to="/order-counts" className={location.pathname === '/order-counts' ? 'active' : ''}>Кол-во Заказов</Link>
              </li>
              <li>
                <Link to="/customer-records" className={location.pathname === '/customer-records' ? 'active' : ''}>Клиенты</Link>
              </li>
            </>
          )}
        </ul>
      </nav>

      {isAuthenticated && <LogoutButton />}
    </div>
  );
};

export default Header;
